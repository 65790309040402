import React, { FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import s from './App.scss';
import ErrorLayout from './ErrorLayout';
import BackgroundImage from './BackgroundImage';
import dataHooks from '../constants/data-hooks';
import classNames from 'classnames';

interface AppProps extends WithTranslation {
  isNotBranded?: boolean;
}

const App: FC<AppProps> = ({ isNotBranded, t }) => {
  return (
    <div className={s.root}>
      <div
        className={classNames(s.container, {
          [s.premium_container]: isNotBranded,
          [s.classic_container]: !isNotBranded,
        })}
        data-hook={dataHooks.errorApp}
      >
        <ErrorLayout isNotBranded />
        {isNotBranded ? null : <BackgroundImage />}
      </div>
    </div>
  );
};

export default withTranslation()(App);
