enum dataHooks {
  errorApp = 'error-app',
  backgroundImage = 'background-image',
  errorLayout = 'error-layout',
  errorDescription = 'error-description',
  errorRequestId = 'error-request-id',
  errorButton = 'error-button',
}

export default dataHooks;
