import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { create as createFedopsLogger } from '@wix/fedops-logger';
import i18n from './i18n';
import App from './components/App';
import { ErrorDataProvider } from './hooks/use-error-data';

const locale = window.__LOCALE__;
const errorData = window.__ERROR_DATA__;
const isNotBranded = errorData.errorCode.endsWith('-NotBranded');

const fedopsLogger = createFedopsLogger('classic-error-pages-statics');

// Move the following `appLoaded()` call to the point where your app has fully loaded.
// See https://github.com/wix-private/fed-infra/blob/master/fedops/fedops-logger/README.md
fedopsLogger.appLoaded();

const domNode = document.getElementById('root')!;
const root = createRoot(domNode);

const supportedLanguages = [
  'en',
  'cs',
  'da',
  'de',
  'es',
  'fr',
  'id',
  'it',
  'ja',
  'ko',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sv',
  'th',
  'tr',
  'uk',
  'vi',
  'zh',
];

const render = () =>
  root.render(
    <Suspense>
      <I18nextProvider i18n={i18n(locale)}>
        <ErrorDataProvider errorData={errorData}>
          <App isNotBranded={isNotBranded} />
        </ErrorDataProvider>
      </I18nextProvider>
    </Suspense>,
  );
const isFreeSite = window.location.origin.indexOf('wixsite.com') >= 0;
isNotBranded ? render() : window['header-footer-provider']
  .initHeaderFooter({
    footer: false,
    languagePickerList: supportedLanguages,
    header: !isNotBranded,
    language: locale,
    hiddenLinks: isFreeSite ? ['language_picker', 'log_in'] : [],
    disableAuthCheck: isFreeSite,
  })
  .then(render)
  .catch(render);
