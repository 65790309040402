import React, { FC } from 'react';
import s from './ErrorLayout.scss';
import ErrorButton from './ErrorButton';
import ErrorDescription from './ErrorDescription';
import { errorMapping } from './errorsMapping';
import { useTranslation } from 'react-i18next';
import dataHooks from '../../constants/data-hooks';
import classNames from 'classnames';

interface ErrorLayoutProps {
  isNotBranded?: boolean;
}

const ErrorLayout: FC<ErrorLayoutProps> = (isNotBranded) => {
  const { t } = useTranslation();
  const { errorCode, requestId } = window.__ERROR_DATA__ || {};

  if (!errorCode) {
    return null;
  }
  const { title, description, buttonLabel, buttonLink, hideCTA } =
    errorMapping[errorCode];

  const cleanErrorCode = errorCode.replace('-NotBranded', '');
  return (
    <div
      className={classNames(s.content, {
        [s.premium_content]: isNotBranded,
      })}
      data-hook={dataHooks.errorLayout}
    >
      <h2 className={s['error-title']}>{t(title)}</h2>
      <h1 className={s['error-code']} data-hook="error-code">
        {cleanErrorCode}
      </h1>
      <ErrorDescription
        description={t(description)}
        requestId={!errorCode.includes('404') ? requestId : undefined}
      />
      {!hideCTA && <ErrorButton link={buttonLink} label={buttonLabel} />}
    </div>
  );
};

export default ErrorLayout;
